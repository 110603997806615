.content.research {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125%;
  height: 100vh;
  overflow: hidden;
  margin-left: -120px;
  margin-top: 1%;
}

.content.research > div {
  width: 125%;
  height: 100%;
  display: grid;
  place-items: center;
}

.tableauPlaceholder {
  width: 100%;
  height: 100%;
}

.tableauViz {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
